<template>
  <b-row>
    <b-col cols="12">
      <b-card title="Detail NPP">
        <b-row>
          <b-col cols="6" md="4" class="mb-2">
            <h6>No. Proposal</h6>
            <p class="card-text mb-25">
              {{ npp.proposal_number }}
            </p>
          </b-col>
          <b-col cols="6" md="4" class="mb-2">
            <h6>Kantor</h6>
            <p class="card-text mb-25">
              {{ npp.regional_name }}
            </p>
          </b-col>
          <b-col cols="6" md="4" class="mb-2">
            <h6>No. Proposal Regional</h6>
            <p class="card-text mb-25">
              {{ npp.code_regional || '-' }}
            </p>
          </b-col>
          <b-col cols="6" md="4" class="mb-2">
            <h6>Jumlah Pegawai</h6>
            <p class="card-text mb-25">
              {{ npp.total_employees }}
            </p>
          </b-col>
          <b-col cols="6" md="4" class="mb-2">
            <h6>Tanggal Generate</h6>
            <p class="card-text mb-25">
              {{ npp.date | moment('DD/MM/YYYY') }}
            </p>
          </b-col>
        </b-row>
        <b-table
          :items="npp.npp_details"
          :fields="fields"
          responsive
          striped
          show-empty
        >
          <template #empty>
            <div class="text-center my-2">Data tidak tersedia</div>
          </template>
          <template #cell(no)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(name)="data">
            {{ data.item.employee }} / {{ data.item.name }}
          </template>
          <template #cell(daily_money)="data">
            {{ data.item.daily_money | currency }}
          </template>
          <template #cell(total_accomodation)="data">
            {{ data.item.total_accomodation | currency }}
          </template>
          <template #cell(total_transportation)="data">
            {{ data.item.total_transportation | currency }}
          </template>
          <template #cell(total_cost)="data">
            {{
              (data.item.daily_money +
                data.item.total_accomodation +
                data.item.total_transportation)
                | currency
            }}
          </template>
          <template #cell(kwitansi)="data">
            <b-button
              :href="data.item.folder_url"
              variant="info"
              target="_blank"
              size="sm"
            >
              Cek Kwitansi
            </b-button>
          </template>
        </b-table>
        <b-card-footer align="right">
          <b-button variant="secondary" @click="$router.back()">
            Kembali
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardFooter, BTable, BButton } from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: { BRow, BCol, BCard, BCardFooter, BTable, BButton },
  data() {
    return {
      npp: {},
      fields: [
        'no',
        { key: 'name', label: 'NIPPOS / Nama' },
        { key: 'sppd_number', label: 'No SPPD' },
        { key: 'daily_money', label: 'Uang Harian' },
        { key: 'total_accomodation', label: 'Biaya Akomodasi' },
        { key: 'total_transportation', label: 'Biaya Transportasi' },
        { key: 'total_cost', label: 'Total Biaya' },
        { key: 'target_condition', label: 'Target Condition' },
        { key: 'kwitansi', label: 'Kwitansi' },
      ],
    }
  },
  mounted() {
    this.getNpp()
  },
  methods: {
    getNpp() {
      axios.get(`/npps/${this.$route.params.id}`).then(res => {
        this.npp = res.data.data
      })
    },
  },
}
</script>

<style></style>
